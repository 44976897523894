












import { Vue, Component, Prop } from 'vue-property-decorator'
import SvgMeasureLines from './SvgMeasureLines.vue'
import CourtColors, { CourtColor } from '../helpers/CourtColors'

const angled = `transform: perspective(15em) translate3d(0, -20%, 40px)  rotate3d(1, 0, 0, 40deg)`
const flat = `transform: translateY(-10%) perspective(0em) rotateX(0deg)`
const lineStyle = `fill:none;stroke:#666;stroke-width:0.2px;`
const textStyle = `fill:#666;stroke:none;`

interface VolleyballViewerData {
  border: CourtColor
  zone1: CourtColor
  zone2: CourtColor
  zone3: CourtColor
  zone4: CourtColor
  kitchen: CourtColor
}

@Component({
  components: {
    SvgMeasureLines
  }
})
export default class VolleyballCourtViewer extends Vue {
  @Prop({ default: 188 }) width!: number
  @Prop({ default: 300 }) height!: number
  @Prop({ default: 'angled' }) angle!: 'flat' | 'angled'
  @Prop({
    default: () => {
      return {
        border: CourtColors[0],
        zone1: CourtColors[0],
        zone2: CourtColors[0],
        zone3: CourtColors[0],
        zone4: CourtColors[0],
        kitchen: CourtColors[0]
      }
    }
  })
  zones!: VolleyballViewerData

  lineStyle = lineStyle
  textStyle = textStyle
  angled = angled
  flat = flat
  trans = { x: 10, y: 10, width: 30, height: 60 }
  lines = [
    {
      start: { x: 32, y: 0 },
      end: { x: 32, y: 60 }
    },
    {
      start: { x: -2, y: 0 },
      end: { x: -2, y: 30 }
    },
    {
      start: { x: 0, y: 62 },
      end: { x: 30, y: 62 }
    },
    {
      label: 'Side Line',
      start: { x: -40, y: -2, r: -90 }
    }
  ]

  get displayAngle(): string {
    return this.angle === 'angled' ? angled : flat
  }
}
